body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
    text-align: left;
}

.mc-container {
    width: 760px;
    margin: 20px auto;
}

.mc-container {
    width: 1024px;
}

.mc-table {
    width: 100%;
}

.mc-table td {
    line-height: 2em;
}

.mc-table-actions {
    line-height: 1em !important;
}

.mc-detail-item {
    margin-bottom: 20px;
}

.mc-detail-item h4 {
    margin-bottom: 10px;
}

.Home .lander {
    padding: 80px 0;
    text-align: center;
}

.Home .lander h1 {
    font-family: 'Open Sans', sans-serif;
    font-weight: 600;
}

.Home .lander p {
    color: #999;
}

form textarea {
    width: 100%;
}

form textarea {
    width: 100%;
}

.mc-visitas-box {
    background-color: #f5f8fa;
    padding: 10px;
    padding-top: 0;
}

.mc-visitas-box-table {
    margin-bottom: 10px;
    width: 100%;
}

.mc-delete-button:hover {
    background-color: #db3737 !important;
    color: #fff !important;
}

.mc-delete-button:hover::before {
    color: #fff !important;
}

.mc-table-actions {
    max-width: 185px;
}

.NotFound {
    padding-top: 100px;
    text-align: center;
}

