.mc-visitas-box {
    background-color: #f5f8fa;
    padding: 10px;
    padding-top: 0;
}

.mc-visitas-box-table {
    margin-bottom: 10px;
    width: 100%;
}
