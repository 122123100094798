@import '~normalize.css';
@import '~@blueprintjs/core/lib/css/blueprint.css';
@import '~@blueprintjs/icons/lib/css/blueprint-icons.css';
@import '~@blueprintjs/datetime/lib/css/blueprint-datetime.css';

.App {
    text-align: left;
}

.mc-container {
    width: 760px;
    margin: 20px auto;
}

.mc-container {
    width: 1024px;
}

.mc-table {
    width: 100%;
}

.mc-table td {
    line-height: 2em;
}

.mc-table-actions {
    line-height: 1em !important;
}

.mc-detail-item {
    margin-bottom: 20px;
}

.mc-detail-item h4 {
    margin-bottom: 10px;
}
