.mc-delete-button:hover {
    background-color: #db3737 !important;
    color: #fff !important;
}

.mc-delete-button:hover::before {
    color: #fff !important;
}

.mc-table-actions {
    max-width: 185px;
}
